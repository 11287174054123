.testimonials-section {
    background-color:#113321; /* Removed quotes */
    padding: 50px 0; /* Removed quotes */
  }
  
  .custom{
    width:80px;
    height: 80px;
    margin-left: 20px;
    margin-top:-10px;
  }
  .rating{
    color:#F5DE10;
  }
  .pos{
    margin-left: 120px;
    margin-top:-70px;
  }
  .rectangle{
    height:270px;
    border-radius: 0;
  }
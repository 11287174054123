.video-component {
    position: relative;
    width: 100%;
  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    object-fit: cover;
  }
  


  .centered1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    width:70%;
  }
  .centered2 {
    position: absolute;
    top: 97%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    width:70%;
  }
  
  .buttons1 {
    margin-top: 20px;
  }
  .word{
    font-size: 55px;
  }
  .des{
    font-size: 35px;
  }
  /* Additional styling can be added as needed */
  @media (max-width:600px){
    .word{
        font-size: 45px;
    }
    .des{
        font-size: 25px;
       
    }

   
  }


.animated-image {
     /* Adjust size as needed */
    animation: upDown 1s ease-in-out infinite;
}

@keyframes upDown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px); /* Adjust the distance of movement */
    }
    100% {
        transform: translateY(0);
    }
}


  

  .content {
    text-align: center;
    color: white;
    width:70%;
  }
  
  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttons {
    margin-top: -36px;
  
  }
  
  .button1 {
    padding: 10px 20px 20px 20px;
    margin: 10 10px;
    border: none;
 
  
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-primary {
    background-color:black;
    color: white;
    width:170px;
  }
  
  .button-secondary {
    background-color: #6c757d;
    color: white;
    width:170px;
  }
  
  .button-primary:hover {
    background-color:#6c757d;
  }
  .button-secondary:hover{
    background-color:black;
  }

